
import {confirmModal} from "../../plugins/modals";
import {VueTimeAgo} from "../../plugins/timeago.js";
import store from "../../store/index";
import VueModal from "../delete-modal.vue";
import VueIcon from "../icon";
import VueUserName from "../user-name.vue";

export default {
  name: 'VuePostLog',
  components: {
    VueIcon,
    'vue-username': VueUserName,
    'vue-modal': VueModal,
    'vue-timeago': VueTimeAgo,
  },
  props: {
    log: {
      type: Object,
      required: true,
    },
    topicLink: {
      type: String,
      required: true,
    },
    isRollbackEnabled: {
      type: Boolean,
      required: true,
    },
    oldStr: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoaded: false,
      diff: null as any,
    };
  },
  created() {
    import('diff').then((diff) => {
      this.diff = diff;
      this.isLoaded = true;
    });
  },
  methods: {
    async rollback() {
      await confirmModal({
        message: 'Treść posta zostanie zastąpiona. Czy chcesz kontynuować?',
        title: 'Potwierdź operację',
        okLabel: 'Tak, przywróć',
      });
      const {data} = await store.dispatch('posts/rollback', this.log);
      window.location.href = data.url;
    },
  },
  computed: {
    diffStr() {
      if (!this.oldStr) {
        return this.log.text;
      }
      const diff = this.diff.diffWords(
        encodeHtml(this.oldStr),
        encodeHtml(this.log.text),
      );

      return diff.reduce((acc: string, part): string => {
        if (part.added) {
          return acc + `<ins class="text-primary">${part.value}</ins>`;
        }
        if (part.removed) {
          return acc + `<del class="text-danger">${part.value}</del>`;
        }
        return acc + part.value;
      }, '');
    },
  },
};

const tmp = document.createElement("div");

function encodeHtml(text: string): string {
  tmp.innerText = text;
  return tmp.innerHTML;
}

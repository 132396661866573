import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row mt-1" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "text-muted float-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_comment_autocomplete = _resolveComponent("vue-comment-autocomplete")!
  const _component_vue_button = _resolveComponent("vue-button")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createVNode(_component_vue_comment_autocomplete, {
      source: `/completion/prompt/users/${_ctx.$store.getters['topics/topic'].id}`,
      "max-length": $data.maxLength,
      disabled: $data.isProcessing,
      placeholder: "Drobne uwagi, sugestie. Nie odpowiadaj tutaj na pytania zawarte w poście.",
      modelValue: $props.comment.text,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.comment.text) = $event)),
      onSave: $options.saveComment,
      onCancel: $options.cancel,
      ref: "commentPrompt"
    }, null, 8 /* PROPS */, ["source", "max-length", "disabled", "modelValue", "onSave", "onCancel"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _cache[2] || (_cache[2] = _createTextVNode(" Pozostało ")),
          _createElementVNode("strong", null, _toDisplayString($data.maxLength - $props.comment.text.length), 1 /* TEXT */),
          _cache[3] || (_cache[3] = _createTextVNode(" znaków "))
        ]),
        _createVNode(_component_vue_button, {
          disabled: $data.isProcessing,
          onClick: $options.saveComment,
          class: "btn btn-sm btn-primary neon-primary-button float-end",
          title: "Kliknij, aby wysłać (Ctrl+Enter)"
        }, {
          default: _withCtx(() => [
            ($options.newComment)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("Komentuj")
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("Zapisz")
                ], 64 /* STABLE_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"]),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.cancel && $options.cancel(...args)), ["prevent"])),
          class: "btn btn-sm btn-danger float-end me-2"
        }, "Anuluj")
      ])
    ])
  ]))
}

import axios from 'axios';
import Prism from 'prismjs';
import {mapGetters, mapMutations, mapState} from "vuex";

import VueMarkdown from '../../components/forms/markdown.vue';
import {loadDraft, removeDraft, saveDraft} from "../../plugins/autosave";
import {confirmModal} from "../../plugins/modals";
import {VueTimeAgo} from "../../plugins/timeago.js";
import store from "../../store";
import {nextTick} from "../../vue";
import VueButton from '../forms/button.vue';
import VueError from '../forms/error.vue';
import VueTagsInline from '../forms/tags-inline.vue';
import VueText from '../forms/text.vue';
import VueIcon from "../icon";
import PerfectScrollbar from '../perfect-scrollbar.js';

export default {
  name: 'forum-form',
  store,
  components: {
    VueIcon,
    'vue-button': VueButton,
    'vue-markdown': VueMarkdown,
    'vue-tags-inline': VueTagsInline,
    'vue-error': VueError,
    'vue-text': VueText,
    'perfect-scrollbar': PerfectScrollbar,
    'vue-timeago': VueTimeAgo,
  },
  props: {
    showTitleInput: {type: Boolean, default: false},
    showTagsInput: {type: Boolean, default: false},
    showStickyCheckbox: {type: Boolean, default: false},
    showDiscussModeSelect: {type: Boolean, default: false},
    requireTag: {type: Boolean, default: false},
    popularTags: {type: Array, default: () => []},
    post: {type: Object, required: true},
    treeAnswerPostId: {type: Number, required: false},
  },
  data() {
    return {
      isProcessing: false,
      currentTab: 0,
      errors: {},
      similar: [],
      emojis: window.emojis,
      originalText: this.post.text,
    };
  },
  watch: {
    poll: {
      handler(poll) {
        store.commit('poll/init', poll);
      },
      deep: true,
    },
  },
  created() {
    this.emojis = window.emojis;
    if (!this.exists) {
      this.post.text = loadDraft(this.draftKey) as string;
      this.$watch('post.text', newValue => saveDraft(this.draftKey, newValue));
    }
    this.originalText = this.post.text;
  },
  methods: {
    focus(): void {
      this.$refs.markdown.focus();
    },
    cancel() {
      this.post.text = this.originalText;
      this.$emit('cancel');
    },
    ...mapMutations('poll', ['removeItem', 'resetDefaults']),
    ...mapMutations('posts', ['deleteAttachment', 'changePage']),
    async save() {
      await this.validateTags();

      this.isProcessing = true;
      this.errors = {};

      await this.lastPage();

      store.dispatch('posts/savePostTreeAnswer',
        [this.post, this.$props.treeAnswerPostId])
        .then(result => {
          this.$emit('save', result.data);
          nextTick(() => {
            removeDraft(this.draftKey);
            Prism.highlightAll();
          });
        })
        .catch(err => {
          if (err.response?.status !== 422) {
            return;
          }
          this.errors = err.response?.data.errors;
        })
        .finally(() => this.isProcessing = false);
    },
    openDialog() {
      (this.$refs.attachment as HTMLInputElement).click();
    },
    toggleTag(tag) {
      store.commit('topics/toggleTag', {topic: this.topic, tag});
    },
    findSimilar() {
      if (!this.topic.title) {
        return;
      }
      axios.get('/completion/similar', {params: {q: this.topic.title}}).then(response => this.similar = response.data.hits);
    },
    addItem() {
      store.commit('poll/addItem');
      nextTick(() => this.$refs['poll-items'][this.$refs['poll-items'].length - 1].focus());
    },
    async lastPage() {
      if (!this.exists && this.currentPage < this.totalPages) {
        history.pushState({page: this.totalPages}, '', `?page=${this.totalPages}`);

        await store.dispatch('posts/changePage', this.totalPages);
      }
    },
    async validateTags() {
      if (!this.topic.tags?.length || !this.isFirstPost) {
        return;
      }

      this.isProcessing = true;
      const response = await axios.post('/Forum/Tag/Validation', {tags: this.topic.tags.map(tag => tag.name)});

      this.isProcessing = false;

      if (!response.data.warning) {
        return;
      }

      await confirmModal({message: response.data.message, title: 'Czy to tag techniczny?', okLabel: 'Tak, jestem pewien'});

      return true;
    },
  },
  computed: {
    ...mapGetters('topics', ['topic', 'is_mode_tree', 'is_mode_linear']),
    ...mapState('poll', ['poll']),
    ...mapGetters('posts', ['totalPages', 'currentPage']),
    isFirstPost() {
      return !this.topic || this.topic.first_post_id === this.post.id;
    },
    draftKey() {
      return `topic-${this.topic.id ? this.topic.id : ''}`;
    },
    exists() {
      return this.post.id !== undefined;
    },
  },
};

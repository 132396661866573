import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_select = _resolveComponent("vue-select")!
  const _component_vue_modal = _resolveComponent("vue-modal")!

  return (_openBlock(), _createBlock(_component_vue_modal, { ref: "modal" }, {
    title: _withCtx(() => _cache[3] || (_cache[3] = [
      _createTextVNode(" Usunąć wpis? ")
    ])),
    buttons: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.close && $options.close(...args))),
        type: "button",
        class: "btn btn-secondary",
        "data-dismiss": "modal"
      }, " Anuluj "),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.deletePost && $options.deletePost(...args))),
        type: "submit",
        class: "btn btn-danger danger"
      }, " Tak, usuń ")
    ]),
    default: _withCtx(() => [
      _cache[4] || (_cache[4] = _createTextVNode(" Tej operacji nie będzie można cofnąć. ")),
      ($props.reasons)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
            _createVNode(_component_vue_select, {
              ref: "select",
              name: "reason_id",
              options: $props.reasons,
              modelValue: $data.reasonId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.reasonId) = $event)),
              class: "form-control-sm",
              placeholder: "-- wybierz --"
            }, null, 8 /* PROPS */, ["options", "modelValue"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}
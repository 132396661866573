
import {nextTick} from "../vue";
import VueSelect from './forms/select.vue';
import VueModal from './modal.vue';

export default {
  name: 'VueDeleteModal',
  components: {
    'vue-modal': VueModal,
    'vue-select': VueSelect,
  },
  emits: ['delete'],
  props: {
    reasons: {type: Object},
  },
  data() {
    return {
      reasonId: null,
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
      nextTick(() => {
        this.$refs.select.focus();
      });
    },
    close() {
      this.$refs.modal.close();
    },
    deletePost() {
      this.$emit('delete', this.reasonId);
      this.close();
    },
  },
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-absolute post-guiderail post-guiderail-to-parent"
}
const _hoisted_2 = {
  key: 1,
  class: "position-absolute post-guiderail post-guiderail-to-child"
}
const _hoisted_3 = {
  key: 2,
  class: "position-absolute post-guiderail post-guiderail-to-toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon = _resolveComponent("vue-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.parentGuiderails, (cssClass) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["position-absolute post-guiderail", cssClass])
      }, null, 2 /* CLASS */))
    }), 256 /* UNKEYED_FRAGMENT */)),
    ($props.linksToParent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("v-if", true),
    ($options.linksToChild)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("v-if", true),
    ($options.toggleVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "position-absolute post-guiderail-button d-flex justify-content-center align-items-center cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggle && $options.toggle(...args))),
            style: {"font-size":"0.7em"}
          }, [
            ($props.expanded)
              ? (_openBlock(), _createBlock(_component_vue_icon, {
                  key: 0,
                  name: "postGuiderailExpanded"
                }))
              : (_openBlock(), _createBlock(_component_vue_icon, {
                  key: 1,
                  name: "postGuiderailCollapsed"
                }))
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}
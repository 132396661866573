
import store from "../../store/index";
import VueCommentAutocomplete from '../CommentAutocomplete.vue';
import VueButton from '../forms/button.vue';

export default {
  name: 'post-comment-form',
  props: {
    comment: {type: Object, required: true},
  },
  components: {
    'vue-comment-autocomplete': VueCommentAutocomplete,
    'vue-button': VueButton,
  },
  data() {
    return {
      isProcessing: false,
      maxLength: 580,
    };
  },
  methods: {
    saveComment() {
      this.$data.isProcessing = true;
      store.dispatch('posts/saveComment', this.comment)
        .then(() => {
          this.$emit('save');
          if (!this.comment.id) {
            this.comment.text = '';
          }
        })
        .finally(() => this.$data.isProcessing = false);
    },
    focus() {
      this.$refs.commentPrompt.focus();
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  computed: {
    newComment(): boolean {
      return typeof this.comment.id === 'undefined';
    },
  },
};

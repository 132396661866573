import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_4 = { class: "neon-avatar-border" }
const _hoisted_5 = { class: "ms-2" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["data-metadata", "data-url"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_flag = _resolveComponent("vue-flag")!
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_comment_form = _resolveComponent("vue-comment-form")!
  const _component_vue_avatar = _resolveComponent("vue-avatar")!
  const _component_vue_username = _resolveComponent("vue-username")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!

  return (_openBlock(), _createElementBlock("div", {
    id: $options.anchor,
    class: _normalizeClass([{'highlight-flash': $options.highlight, 'not-read': $props.comment.is_read === false}, "post-comment"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.flags, (flag) => {
      return (_openBlock(), _createBlock(_component_vue_flag, {
        key: flag.id,
        flag: flag
      }, null, 8 /* PROPS */, ["flag"]))
    }), 128 /* KEYED_FRAGMENT */)),
    _createElementVNode("div", {
      class: _normalizeClass({'comment-delete-border':$options.authorBlocked, 'comment-delete-border--expanded':$options.authorBlocked && $data.blockedExpanded})
    }, [
      ($options.authorBlocked)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "comment-delete p-2 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.blockedToggle && $options.blockedToggle(...args)))
          }, [
            _createVNode(_component_vue_icon, { name: "postCommentAuthorBlocked" }),
            _cache[6] || (_cache[6] = _createTextVNode(" Treść komentarza została ukryta, ponieważ autorem jest zablokowany przez Ciebie użytkownik. "))
          ]))
        : _createCommentVNode("v-if", true),
      (!$options.authorBlocked || $data.blockedExpanded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            ($props.comment.is_editing && !$options.authorBlocked)
              ? (_openBlock(), _createBlock(_component_vue_comment_form, {
                  key: 0,
                  comment: $props.comment,
                  onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.commit('posts/editEnd', $props.comment))),
                  onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.commit('posts/editEnd', $props.comment))),
                  ref: "comment-form"
                }, null, 8 /* PROPS */, ["comment"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_vue_avatar, _mergeProps($props.comment.user, {
                        "is-online": $props.comment.user.is_online,
                        class: "i-35"
                      }), null, 16 /* FULL_PROPS */, ["is-online"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_vue_username, {
                        user: $props.comment.user,
                        owner: $props.comment.user.id === _ctx.topic.owner_id
                      }, null, 8 /* PROPS */, ["user", "owner"]),
                      _cache[7] || (_cache[7] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
                      _createElementVNode("a", {
                        href: $props.comment.url
                      }, [
                        _createVNode(_component_vue_timeago, {
                          datetime: $props.comment.created_at,
                          class: "text-muted small"
                        }, null, 8 /* PROPS */, ["datetime"])
                      ], 8 /* PROPS */, _hoisted_6),
                      (!$options.authorBlocked)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            ($props.comment.editable)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.edit && $options.edit(...args))),
                                  title: "Edytuj ten komentarz",
                                  class: "btn-comment cursor-pointer"
                                }, [
                                  _createVNode(_component_vue_icon, { name: "postCommentEdit" })
                                ]))
                              : _createCommentVNode("v-if", true),
                            ($props.comment.editable)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 1,
                                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.deleteComment && $options.deleteComment(...args))),
                                  title: "Usuń ten komentarz",
                                  class: "btn-comment cursor-pointer"
                                }, [
                                  _createVNode(_component_vue_icon, { name: "postCommentDelete" })
                                ]))
                              : _createCommentVNode("v-if", true),
                            ($props.comment.editable)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 2,
                                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.migrate && $options.migrate(...args))),
                                  title: "Zamień w post",
                                  class: "btn-comment cursor-pointer"
                                }, [
                                  _createVNode(_component_vue_icon, { name: "postCommentConvertToPost" })
                                ]))
                              : _createCommentVNode("v-if", true),
                            _createElementVNode("span", {
                              "data-metadata": $props.comment.metadata,
                              "data-url": $props.comment.url,
                              title: "Zgłoś ten komentarz",
                              class: "btn-comment cursor-pointer"
                            }, [
                              _createVNode(_component_vue_icon, { name: "postCommentReport" })
                            ], 8 /* PROPS */, _hoisted_7)
                          ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("span", {
                      innerHTML: $props.comment.html,
                      class: "comment-text neon-contains-a-color-link"
                    }, null, 8 /* PROPS */, _hoisted_8)
                  ])
                ]))
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}

import {mapGetters} from "vuex";
import {confirmModal} from "../../plugins/modals";
import {VueTimeAgo} from "../../plugins/timeago.js";
import store from "../../store/index";
import {nextTick} from "../../vue";
import VueAvatar from "../avatar.vue";
import VueFlag from "../flags/flag.vue";
import VueIcon from "../icon";
import {default as mixins} from '../mixins/user.js';
import VueUserName from '../user-name.vue';
import VueCommentForm from "./comment-form.vue";

export default {
  name: 'comment',
  mixins: [mixins],
  components: {
    VueIcon,
    'vue-username': VueUserName,
    'vue-avatar': VueAvatar,
    'vue-comment-form': VueCommentForm,
    'vue-flag': VueFlag,
    'vue-timeago': VueTimeAgo,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      blockedExpanded: false,
    };
  },
  computed: {
    ...mapGetters('topics', ['topic']),
    anchor() {
      return `comment-${this.comment.id}`;
    },
    highlight() {
      return '#' + this.anchor === window.location.hash;
    },
    flags() {
      return [
        ...store.getters['flags/filter'](this.comment.id, 'Coyote\\Comment'),
        ...store.getters['flags/filter'](this.comment.id, 'Coyote\\Post\\Comment'),
      ];
    },
    authorBlocked(): boolean {
      return store.getters['user/isBlocked'](this.comment.user.id);
    },
  },
  methods: {
    blockedToggle() {
      this.$data.blockedExpanded = !this.$data.blockedExpanded;
    },
    edit() {
      store.commit('posts/editStart', this.comment);
      nextTick(() => this.$refs['comment-form'].focus());
    },
    deleteComment() {
      confirmModal({
        message: 'Tej operacji nie będzie można cofnąć.',
        title: 'Usunąć komentarz?',
        okLabel: 'Tak, usuń',
      })
        .then(() => store.dispatch('posts/deleteComment', this.comment));
    },
    migrate() {
      confirmModal({
        message: 'Tej operacji nie będzie można cofnąć.',
        title: 'Zamienić na post?',
        okLabel: 'Tak, zamień',
      })
        .then(() => {
          store
            .dispatch('posts/migrateComment', this.comment)
            .then(response => window.location.href = response.data.url);
        });
    },
  },
};

import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createSlots as _createSlots, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-group"
}
const _hoisted_2 = {
  key: 0,
  class: "text-muted form-text"
}
const _hoisted_3 = {
  key: 1,
  class: "card mt-2"
}
const _hoisted_4 = { class: "card-body related mt-0 p-3" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "form-group"
}
const _hoisted_7 = { class: "ms-auto" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  key: 0,
  class: "col-form-label"
}
const _hoisted_10 = {
  href: "javascript:",
  "data-bs-target": "#js-poll-form",
  "data-bs-toggle": "collapse",
  class: "ms-1 small text-muted"
}
const _hoisted_11 = {
  key: 0,
  id: "js-poll-form",
  class: "bg-light p-3 mt-2 collapse"
}
const _hoisted_12 = { class: "form-group row" }
const _hoisted_13 = { class: "col-md-6" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "form-group row" }
const _hoisted_16 = { class: "col-md-6" }
const _hoisted_17 = { class: "form-group row" }
const _hoisted_18 = { class: "col-md-6" }
const _hoisted_19 = {
  key: 0,
  class: "form-group row"
}
const _hoisted_20 = { class: "col-md-6 offset-md-4" }
const _hoisted_21 = {
  key: 0,
  class: "p-1 d-flex"
}
const _hoisted_22 = { class: "d-flex justify-content-between" }
const _hoisted_23 = {
  key: 0,
  class: "form-group"
}
const _hoisted_24 = { class: "custom-control custom-checkbox" }
const _hoisted_25 = {
  class: "custom-control-label",
  for: "is-sticky"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_text = _resolveComponent("vue-text")!
  const _component_vue_error = _resolveComponent("vue-error")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_tags_inline = _resolveComponent("vue-tags-inline")!
  const _component_vue_markdown = _resolveComponent("vue-markdown")!
  const _component_vue_button = _resolveComponent("vue-button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => ($options.save && $options.save(...args)), ["prevent"]))
  }, [
    ($props.showTitleInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col-form-label" }, [
            _createTextVNode(" Temat "),
            _createElementVNode("em", null, "*")
          ], -1 /* HOISTED */)),
          _createVNode(_component_vue_text, {
            modelValue: _ctx.topic.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.topic.title) = $event)),
            "is-invalid": 'title' in $data.errors,
            onAccept: $options.save,
            onLeave: $options.findSimilar,
            name: "title",
            tabindex: "1",
            autofocus: "autofocus"
          }, null, 8 /* PROPS */, ["modelValue", "is-invalid", "onAccept", "onLeave"]),
          _createVNode(_component_vue_error, {
            message: $data.errors['title']
          }, null, 8 /* PROPS */, ["message"]),
          (!('title' in $data.errors))
            ? (_openBlock(), _createElementBlock("small", _hoisted_2, " Bądź rzeczowy. Nie nadawaj wątkom jednowyrazowych tytułów. "))
            : _createCommentVNode("v-if", true),
          ($data.similar.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "card-header p-3" }, "Podobne wątki", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_perfect_scrollbar, {
                    tag: "ul",
                    class: "position-relative",
                    style: {"height":"100px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.similar, (topic) => {
                        return (_openBlock(), _createElementBlock("li", null, [
                          _createElementVNode("a", {
                            href: topic.url
                          }, [
                            _createElementVNode("strong", null, _toDisplayString(topic.title), 1 /* TEXT */),
                            _createElementVNode("small", null, [
                              _createVNode(_component_vue_timeago, {
                                datetime: topic.last_post_created_at
                              }, null, 8 /* PROPS */, ["datetime"])
                            ])
                          ], 8 /* PROPS */, _hoisted_5)
                        ]))
                      }), 256 /* UNKEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    ($props.showDiscussModeSelect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[12] || (_cache[12] = _createElementVNode("label", { class: "col-form-label" }, [
            _createTextVNode(" Rodzaj wątku "),
            _createElementVNode("em", null, "*")
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              name: "discussMode",
              style: {"cursor":"pointer"},
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.topic.discuss_mode) = $event))
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("option", { value: "linear" }, "Posty i komentarze (standardowy)", -1 /* HOISTED */),
              _createElementVNode("option", { value: "tree" }, "Posty jako odpowiedzi innych postów (eksperymentalny)", -1 /* HOISTED */)
            ]), 512 /* NEED_PATCH */), [
              [_vModelSelect, _ctx.topic.discuss_mode]
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.is_mode_linear)
        ? (_openBlock(), _createElementBlock("label", _hoisted_9, _cache[13] || (_cache[13] = [
            _createTextVNode(" Treść "),
            _createElementVNode("em", null, "*", -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_vue_markdown, {
        modelValue: $props.post.text,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($props.post.text) = $event)),
        "prompt-url": `/completion/prompt/users/${_ctx.topic.id || ''}`,
        error: $data.errors['text'],
        assets: $props.post.assets,
        emojis: $data.emojis,
        "preview-url": "/Forum/Preview",
        onSave: $options.save,
        onCancel: $options.cancel,
        ref: "markdown"
      }, _createSlots({
        bottom: _withCtx(() => [
          ($props.showTagsInput)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_vue_tags_inline, {
                  tags: _ctx.topic.tags,
                  class: _normalizeClass({'is-invalid': 'tags' in $data.errors}),
                  "popular-tags": $props.popularTags,
                  placeholder: $props.requireTag ? 'Minimum 1 tag jest wymagany': '...inny? kliknij, aby wybrać tag',
                  onChange: $options.toggleTag
                }, null, 8 /* PROPS */, ["tags", "class", "popular-tags", "placeholder", "onChange"]),
                _createVNode(_component_vue_error, {
                  message: $data.errors['tags']
                }, null, 8 /* PROPS */, ["message"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        default: _withCtx(() => [
          ($options.isFirstPost)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", { class: "col-md-3 col-form-label text-end" }, " Odpowiedzi w ankiecie ", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.poll.items, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: "input-group mb-1"
                      }, [
                        _createElementVNode("a", {
                          onClick: ($event: any) => (_ctx.removeItem(item)),
                          class: _normalizeClass(["input-group-text text-decoration-none", _ctx.poll.items.length > 2 ? 'text-danger' : 'text-muted']),
                          href: "javascript:",
                          title: "Usuń odpowiedź"
                        }, [
                          _createVNode(_component_vue_icon, { name: "postPollRemoveOption" })
                        ], 10 /* CLASS, PROPS */, _hoisted_14),
                        _createVNode(_component_vue_text, {
                          modelValue: item.text,
                          "onUpdate:modelValue": ($event: any) => ((item.text) = $event),
                          "is-invalid": `poll.items.${index}.text` in $data.errors,
                          ref_for: true,
                          ref: "poll-items",
                          class: "input-sm",
                          onAccept: $options.addItem,
                          placeholder: "Naciśnij Enter, aby dodać kolejną pozycję"
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "is-invalid", "onAccept"]),
                        _createVNode(_component_vue_error, {
                          message: $data.errors[`poll.items.${index}.text`]
                        }, null, 8 /* PROPS */, ["message"])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", { class: "col-md-3 col-form-label text-end" }, " Możliwych odpowiedzi ", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_vue_text, {
                      modelValue: _ctx.poll.max_items,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.poll.max_items) = $event)),
                      "is-invalid": `poll.max_items` in $data.errors,
                      class: "input-sm"
                    }, null, 8 /* PROPS */, ["modelValue", "is-invalid"]),
                    _createVNode(_component_vue_error, {
                      message: $data.errors['poll.max_items']
                    }, null, 8 /* PROPS */, ["message"]),
                    _cache[16] || (_cache[16] = _createElementVNode("span", { class: "form-text text-muted" }, " Minimalnie jedna możliwa odpowiedź w ankiecie. ", -1 /* HOISTED */))
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[19] || (_cache[19] = _createElementVNode("label", { class: "col-md-3 col-form-label text-end" }, " Długość działania ", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_vue_text, {
                      modelValue: _ctx.poll.length,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.poll.length) = $event)),
                      "is-invalid": `poll.length` in $data.errors,
                      class: "input-sm"
                    }, null, 8 /* PROPS */, ["modelValue", "is-invalid"]),
                    _createVNode(_component_vue_error, {
                      message: $data.errors['poll.length']
                    }, null, 8 /* PROPS */, ["message"]),
                    _cache[18] || (_cache[18] = _createElementVNode("span", { class: "form-text text-muted" }, " Określ długość działania ankiety (w dniach). 0 oznacza brak terminu ważności. ", -1 /* HOISTED */))
                  ])
                ]),
                (_ctx.poll.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("button", {
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.resetDefaults && _ctx.resetDefaults(...args))),
                          class: "btn btn-danger btn-sm"
                        }, " Usuń ankietę ")
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, [
        ($options.isFirstPost)
          ? {
              name: "options",
              fn: _withCtx(() => [
                _createElementVNode("a", _hoisted_10, [
                  _createVNode(_component_vue_icon, { name: "postPoll" }),
                  _cache[14] || (_cache[14] = _createElementVNode("span", { class: "d-none d-sm-inline" }, " Ankieta ", -1 /* HOISTED */))
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "prompt-url", "error", "assets", "emojis", "onSave", "onCancel"])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", null, [
        ($props.showStickyCheckbox)
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("label", _hoisted_25, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.topic.is_sticky) = $event)),
                    type: "checkbox",
                    class: "custom-control-input",
                    id: "is-sticky"
                  }, null, 512 /* NEED_PATCH */), [
                    [_vModelCheckbox, _ctx.topic.is_sticky]
                  ]),
                  _cache[20] || (_cache[20] = _createTextVNode(" Przyklejony wątek "))
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", null, [
        ($props.post.id)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($options.cancel && $options.cancel(...args))),
              title: "Anuluj (Esc)",
              class: "btn btn-sm btn-danger ms-2"
            }, " Anuluj "))
          : _createCommentVNode("v-if", true),
        _cache[21] || (_cache[21] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
        _createVNode(_component_vue_button, {
          disabled: $data.isProcessing,
          title: "Kliknij, aby zapisać (Ctrl+Enter)",
          class: "btn btn-primary btn-sm neon-primary-button",
          onClick: $options.save
        }, {
          default: _withCtx(() => [
            ($props.post.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("Zapisz")
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("Dodaj post")
                ], 64 /* STABLE_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"])
      ])
    ])
  ], 32 /* NEED_HYDRATION */))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card card-post" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "row d-none d-lg-flex" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "mb-0 post-author ms-2" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "col-10" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "d-none d-lg-block col-lg-2" }
const _hoisted_12 = { class: "post-stats list-unstyled" }
const _hoisted_13 = { class: "text-truncate" }
const _hoisted_14 = ["title"]
const _hoisted_15 = { class: "col-12 col-lg-10 diff" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "card-footer" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-12 d-flex col-lg-10" }
const _hoisted_20 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_username = _resolveComponent("vue-username")!
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, [
            ($props.log.user)
              ? (_openBlock(), _createBlock(_component_vue_username, {
                  key: 0,
                  user: $props.log.user
                }, null, 8 /* PROPS */, ["user"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.log.user_name), 1 /* TEXT */))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_vue_icon, { name: "postHistoryVersion" }),
          _createElementVNode("a", {
            href: `#id${$props.log.id}`
          }, _toDisplayString($props.log.title), 9 /* TEXT, PROPS */, _hoisted_8)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("ul", _hoisted_12, [
            _createElementVNode("li", null, [
              _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Data:", -1 /* HOISTED */)),
              _createElementVNode("small", null, [
                _createVNode(_component_vue_timeago, {
                  datetime: $props.log.created_at
                }, null, 8 /* PROPS */, ["datetime"])
              ])
            ]),
            _createElementVNode("li", null, [
              _cache[2] || (_cache[2] = _createElementVNode("strong", null, "IP:", -1 /* HOISTED */)),
              _createElementVNode("small", null, _toDisplayString($props.log.ip), 1 /* TEXT */)
            ]),
            _createElementVNode("li", _hoisted_13, [
              _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Przeglądarka:", -1 /* HOISTED */)),
              _createElementVNode("small", {
                title: $props.log.browser
              }, _toDisplayString($props.log.browser), 9 /* TEXT, PROPS */, _hoisted_14)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          ($data.isLoaded)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "post-content",
                innerHTML: $options.diffStr,
                style: {"white-space":"pre-wrap"}
              }, null, 8 /* PROPS */, _hoisted_16))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "d-none d-lg-block col-lg-2" }, null, -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_19, [
          ($props.isRollbackEnabled)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.rollback && $options.rollback(...args))),
                title: "Cofnij do tej wersji",
                class: "btn btn-sm btn-rollback"
              }, [
                _createVNode(_component_vue_icon, { name: "postHistoryVersionRestore" }),
                _cache[4] || (_cache[4] = _createTextVNode(" Cofnij do tej wersji "))
              ]))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "btn btn-sm",
                href: this.topicLink
              }, [
                _createVNode(_component_vue_icon, { name: "postHistoryVersionShow" }),
                _cache[5] || (_cache[5] = _createTextVNode(" Pokaż aktualną wersję "))
              ], 8 /* PROPS */, _hoisted_20))
        ])
      ])
    ])
  ]))
}
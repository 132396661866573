
import {mapGetters} from "vuex";
import {VueTimeAgo} from "../../plugins/timeago.js";
import store from "../../store";
import {Poll, PollItem} from '../../types/models';
import VueButton from "../forms/button.vue";
import mixins from '../mixins/user.js';

export default {
  name: 'forum-poll',
  mixins: [mixins],
  store,
  components: {
    'vue-button': VueButton,
    'vue-timeago': VueTimeAgo,
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkedOptions: [] as number[],
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isAuthorized']),
    totalVotes() {
      return this.poll.items.reduce((total, curr) => total += curr.total, 0);
    },
    isVoteable() {
      return this.poll.votes?.length === 0 && this.isAuthorized && !this.poll.expired;
    },
    pollSync: {
      get(): Poll {
        return this.poll;
      },
      set(value: Poll) {
        this.$emit('update:poll', value);
      },
    },
  },
  methods: {
    percentage(item: PollItem) {
      return this.totalVotes ? Math.round(100 * item.total / this.totalVotes) : 0;
    },
    vote() {
      this.isProcessing = true;
      store.dispatch('poll/vote', Array.isArray(this.checkedOptions) ? this.checkedOptions : [this.checkedOptions])
        .finally(() => this.isProcessing = false);
    },
  },
};

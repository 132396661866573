import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, renderList as _renderList, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  key: 0,
  class: "neon-avatar-border me-1"
}
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "mb-0 post-author ms-2" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "col-10 text-truncate small" }
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = {
  key: 1,
  class: "not-read",
  title: "Nowy post"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 0,
  class: "media-left me-2"
}
const _hoisted_13 = {
  key: 0,
  class: "neon-avatar-border"
}
const _hoisted_14 = { class: "media-body" }
const _hoisted_15 = { class: "mb-0 post-author me-2" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["href"]
const _hoisted_18 = ["title"]
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "d-flex" }
const _hoisted_21 = { class: "neon-avatar-border" }
const _hoisted_22 = {
  key: 0,
  class: "badge badge-secondary mb-1"
}
const _hoisted_23 = { class: "post-stats list-unstyled" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { key: 2 }
const _hoisted_27 = { key: 3 }
const _hoisted_28 = ["href"]
const _hoisted_29 = { class: "post-vote" }
const _hoisted_30 = ["aria-label", "textContent"]
const _hoisted_31 = ["aria-label"]
const _hoisted_32 = {
  key: 2,
  class: "vote-accept on"
}
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = {
  key: 0,
  class: "list-unstyled mb-1"
}
const _hoisted_35 = { class: "small" }
const _hoisted_36 = ["href"]
const _hoisted_37 = ["innerHTML"]
const _hoisted_38 = {
  key: 0,
  class: "edit-info"
}
const _hoisted_39 = {
  key: 1,
  class: "post-comments"
}
const _hoisted_40 = {
  key: 0,
  class: "d-inline-block mb-2 show-all-comments"
}
const _hoisted_41 = { class: "row" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = ["aria-label"]
const _hoisted_44 = {
  key: 0,
  class: "text-primary"
}
const _hoisted_45 = ["textContent"]
const _hoisted_46 = { class: "dropdown d-inline-block" }
const _hoisted_47 = {
  class: "post-action",
  "data-bs-toggle": "dropdown"
}
const _hoisted_48 = { class: "dropdown-menu dropdown-menu-start" }
const _hoisted_49 = ["onClick"]
const _hoisted_50 = {
  key: 0,
  class: "text-primary"
}
const _hoisted_51 = {
  key: 1,
  class: "dropdown float-end"
}
const _hoisted_52 = {
  class: "post-action",
  "data-bs-toggle": "dropdown"
}
const _hoisted_53 = { class: "dropdown-menu dropdown-menu-end" }
const _hoisted_54 = {
  key: 0,
  class: "dropdown-divider"
}
const _hoisted_55 = ["href"]
const _hoisted_56 = ["onClick"]
const _hoisted_57 = {
  key: 3,
  class: "row"
}
const _hoisted_58 = {
  key: 0,
  class: "d-none d-lg-block col-lg-2"
}
const _hoisted_59 = { style: {"margin-bottom":"18px"} }
const _hoisted_60 = {
  class: "d-flex align-items-center",
  style: {"margin-left":"50px"}
}
const _hoisted_61 = { key: 0 }
const _hoisted_62 = ["href"]
const _hoisted_63 = { key: 1 }
const _hoisted_64 = { style: {"width":"38px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_post_guiderail = _resolveComponent("vue-post-guiderail")!
  const _component_vue_avatar = _resolveComponent("vue-avatar")!
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!
  const _component_vue_username = _resolveComponent("vue-username")!
  const _component_vue_flag = _resolveComponent("vue-flag")!
  const _component_vue_see_more = _resolveComponent("vue-see-more")!
  const _component_vue_tags = _resolveComponent("vue-tags")!
  const _component_vue_comment = _resolveComponent("vue-comment")!
  const _component_vue_comment_form = _resolveComponent("vue-comment-form")!
  const _component_vue_form = _resolveComponent("vue-form")!
  const _component_vue_modal = _resolveComponent("vue-modal")!
  const _component_vue_gallery = _resolveComponent("vue-gallery")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["position-relative", [$options.postIndentCssClasses, {'tree-post':_ctx.is_mode_tree}]])
    }, [
      ($options.guiderailVisible)
        ? (_openBlock(), _createBlock(_component_vue_post_guiderail, {
            key: 0,
            "links-to-parent": $options.linksToParent,
            "link-to-child": $options.linkToChild,
            "parent-levels": $options.parentLevels,
            expanded: $options.childrenFolded,
            onToggle: $options.guiderailToggle
          }, null, 8 /* PROPS */, ["links-to-parent", "link-to-child", "parent-levels", "expanded", "onToggle"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          ($props.post.user && _ctx.is_mode_tree)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_vue_avatar, {
                  name: $props.post.user.name,
                  photo: $props.post.user.photo,
                  initials: $props.post.user.initials,
                  "is-online": $props.post.user.is_online,
                  style: {"width":"40px","z-index":"1"}
                }, null, 8 /* PROPS */, ["name", "photo", "initials", "is-online"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", {
          id: $options.anchor,
          class: _normalizeClass(["neon-tile neon-rounded card-post neon-post flex-grow-1 mb-0", {'is-deleted': $options.hidden, 'not-read': !$props.post.is_read, 'highlight-flash': $options.highlight, 'post-deleted-collapsed': $data.isCollapsed}]),
          style: {minWidth:0}
        }, [
          ($props.post.deleted_at)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggleDeletedPost && $options.toggleDeletedPost(...args))),
                class: _normalizeClass(["post-delete card-body text-decoration-none", {'cursor-pointer':!$options.postObscured}])
              }, [
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "mb-3" }, [
                  _createElementVNode("strong", null, "Usunięto")
                ], -1 /* HOISTED */)),
                _createVNode(_component_vue_icon, { name: "postDeleted" }),
                _cache[17] || (_cache[17] = _createTextVNode(" Post usunięty ")),
                _createVNode(_component_vue_timeago, {
                  datetime: $props.post.deleted_at
                }, null, 8 /* PROPS */, ["datetime"]),
                ($props.post.deleter_name)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(' ') + " przez " + _toDisplayString($props.post.deleter_name) + ". ", 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(".")
                    ], 64 /* STABLE_FRAGMENT */)),
                ($props.post.delete_reason)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(' ') + " Powód: " + _toDisplayString($props.post.delete_reason) + ". ", 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */))
            : ($options.authorBlocked)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "post-delete card-body text-decoration-none",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.isCollapsed = !$data.isCollapsed))
                }, [
                  _createVNode(_component_vue_icon, { name: "postAuthorBlocked" }),
                  _cache[18] || (_cache[18] = _createTextVNode(" Treść posta została ukryta, ponieważ autorem jest zablokowany przez Ciebie użytkownik. "))
                ]))
              : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: _normalizeClass([{'collapse': $data.isCollapsed, 'd-lg-block': !$data.isCollapsed && _ctx.is_mode_linear}, "d-none p-2 backport-post-header"])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h5", _hoisted_6, [
                  ($props.post.user)
                    ? (_openBlock(), _createBlock(_component_vue_username, {
                        key: 0,
                        user: $props.post.user,
                        owner: $props.post.user_id === _ctx.topic.owner_id
                      }, null, 8 /* PROPS */, ["user", "owner"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.post.user_name), 1 /* TEXT */))
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    ($props.post.is_read)
                      ? (_openBlock(), _createBlock(_component_vue_icon, {
                          key: 0,
                          name: "postWasRead"
                        }))
                      : (_openBlock(), _createElementBlock("i", _hoisted_10)),
                    _cache[19] || (_cache[19] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
                    _createElementVNode("a", {
                      href: $props.post.url,
                      class: "small"
                    }, [
                      _createVNode(_component_vue_timeago, {
                        datetime: $props.post.created_at
                      }, null, 8 /* PROPS */, ["datetime"])
                    ], 8 /* PROPS */, _hoisted_11)
                  ])
                ])
              ])
            ])
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass([{'collapse': $data.isCollapsed}, "card-body"])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["media mb-2", {'d-lg-none':_ctx.is_mode_linear}])
            }, [
              (_ctx.is_mode_linear)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    ($props.post.user)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createVNode(_component_vue_avatar, {
                            name: $props.post.user.name,
                            photo: $props.post.user.photo,
                            "is-online": $props.post.user.is_online,
                            initials: $props.post.user.initials,
                            class: "i-35"
                          }, null, 8 /* PROPS */, ["name", "photo", "is-online", "initials"])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, [
                  ($props.post.user)
                    ? (_openBlock(), _createBlock(_component_vue_username, {
                        key: 0,
                        user: $props.post.user,
                        owner: $props.post.user_id === _ctx.topic.owner_id
                      }, null, 8 /* PROPS */, ["user", "owner"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString($props.post.user_name), 1 /* TEXT */))
                ]),
                _createElementVNode("a", {
                  href: $props.post.url,
                  class: "text-muted small"
                }, [
                  _createVNode(_component_vue_timeago, {
                    datetime: $props.post.created_at
                  }, null, 8 /* PROPS */, ["datetime"])
                ], 8 /* PROPS */, _hoisted_17),
                ($props.post.edit_count && _ctx.is_mode_tree)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "ms-1",
                      title: 'edytowany ' + $props.post.edit_count + 'x, ostatnio przez ' + $props.post.editor.name + ', ' + $options.editedTimeAgo
                    }, " (edytowany) ", 8 /* PROPS */, _hoisted_18))
                  : _createCommentVNode("v-if", true)
              ])
            ], 2 /* CLASS */),
            _createElementVNode("div", _hoisted_19, [
              (_ctx.is_mode_linear)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["d-none", {'d-lg-block col-lg-2':_ctx.is_mode_linear}])
                  }, [
                    ($props.post.user)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              ($props.post.user)
                                ? (_openBlock(), _createBlock(_component_vue_avatar, {
                                    key: 0,
                                    name: $props.post.user.name,
                                    photo: $props.post.user.photo,
                                    initials: $props.post.user.initials,
                                    "is-online": $props.post.user.is_online,
                                    class: "i-70"
                                  }, null, 8 /* PROPS */, ["name", "photo", "initials", "is-online"]))
                                : _createCommentVNode("v-if", true)
                            ])
                          ]),
                          ($props.post.user.group_name && !_ctx.is_mode_tree)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString($props.post.user.group_name), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("ul", _hoisted_23, [
                            (_ctx.is_mode_linear)
                              ? (_openBlock(), _createElementBlock("li", _hoisted_24, [
                                  _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Rejestracja:", -1 /* HOISTED */)),
                                  _createElementVNode("small", null, _toDisplayString($options.formatDistanceToNow($props.post.user.created_at)), 1 /* TEXT */)
                                ]))
                              : _createCommentVNode("v-if", true),
                            (_ctx.is_mode_linear)
                              ? (_openBlock(), _createElementBlock("li", _hoisted_25, [
                                  _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Ostatnio:", -1 /* HOISTED */)),
                                  _createElementVNode("small", null, _toDisplayString($options.formatDistanceToNow($props.post.user.visited_at ? $props.post.user.visited_at : $props.post.user.created_at)), 1 /* TEXT */)
                                ]))
                              : _createCommentVNode("v-if", true),
                            (_ctx.is_mode_linear && $props.post.user.location)
                              ? (_openBlock(), _createElementBlock("li", _hoisted_26, [
                                  _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Lokalizacja:", -1 /* HOISTED */)),
                                  _createElementVNode("small", null, _toDisplayString($props.post.user.location), 1 /* TEXT */)
                                ]))
                              : _createCommentVNode("v-if", true),
                            (_ctx.is_mode_linear && $props.post.user.allow_count)
                              ? (_openBlock(), _createElementBlock("li", _hoisted_27, [
                                  _cache[23] || (_cache[23] = _createElementVNode("strong", null, "Postów:", -1 /* HOISTED */)),
                                  _createElementVNode("small", null, [
                                    _createElementVNode("a", {
                                      title: "Znajdź posty tego użytkownika",
                                      class: "neon-post-counter",
                                      href: `/Forum/User/${$props.post.user.id}`,
                                      style: {"text-decoration":"underline"}
                                    }, _toDisplayString($props.post.user.posts), 9 /* TEXT, PROPS */, _hoisted_28)
                                  ])
                                ]))
                              : _createCommentVNode("v-if", true)
                          ])
                        ], 64 /* STABLE_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ], 2 /* CLASS */))
                : _createCommentVNode("v-if", true),
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["col-12", {'col-lg-10':_ctx.is_mode_linear}])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.flags, (flag) => {
                  return (_openBlock(), _createBlock(_component_vue_flag, {
                    key: flag.id,
                    flag: flag
                  }, null, 8 /* PROPS */, ["flag"]))
                }), 128 /* KEYED_FRAGMENT */)),
                _createElementVNode("div", _hoisted_29, [
                  (_ctx.is_mode_linear)
                    ? (_openBlock(), _createElementBlock("strong", {
                        key: 0,
                        class: "vote-count",
                        title: "Ocena posta",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadVoters($props.post))),
                        "aria-label": $options.voters,
                        "data-balloon-pos": "left",
                        "data-balloon-break": "",
                        textContent: _toDisplayString($props.post.score)
                      }, null, 8 /* PROPS */, _hoisted_30))
                    : _createCommentVNode("v-if", true),
                  (!$options.hidden && _ctx.is_mode_linear)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: _normalizeClass([{'on': $props.post.is_voted}, "vote-up"]),
                        "aria-label": $options.voters,
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkAuth(_ctx.vote, $props.post))),
                        onMouseenterOnce: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadVoters($props.post))),
                        "data-balloon-pos": "left",
                        "data-balloon-break": "",
                        href: "javascript:"
                      }, [
                        ($props.post.is_voted)
                          ? (_openBlock(), _createBlock(_component_vue_icon, {
                              key: 0,
                              name: "postVoted"
                            }))
                          : (_openBlock(), _createBlock(_component_vue_icon, {
                              key: 1,
                              name: "postVote"
                            }))
                      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_31))
                    : _createCommentVNode("v-if", true),
                  ($props.post.is_accepted)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_32, [
                        _createVNode(_component_vue_icon, { name: "postAccept" })
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", {
                  class: "post-content neon-contains-a-color-link",
                  style: _normalizeStyle(_ctx.is_mode_tree ? {minHeight:'initial'} : {})
                }, [
                  _createVNode(_component_vue_see_more, { height: 700 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: $props.post.html,
                        ref: "postContent"
                      }, null, 8 /* PROPS */, _hoisted_33)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  ($props.post.assets.length)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_34, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.post.assets, (asset) => {
                          return (_openBlock(), _createElementBlock("li", _hoisted_35, [
                            _createVNode(_component_vue_icon, { name: "postAssetDownload" }),
                            _cache[24] || (_cache[24] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
                            _createElementVNode("a", {
                              href: `/assets/${asset.id}/${asset.name}`
                            }, _toDisplayString(asset.name), 9 /* TEXT, PROPS */, _hoisted_36),
                            _cache[25] || (_cache[25] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
                            _createElementVNode("small", null, [
                              _createTextVNode("(" + _toDisplayString(_ctx.size(asset.size)) + ") - ", 1 /* TEXT */),
                              _createElementVNode("em", null, "ściągnięć: " + _toDisplayString(asset.count), 1 /* TEXT */)
                            ])
                          ]))
                        }), 256 /* UNKEYED_FRAGMENT */))
                      ]))
                    : _createCommentVNode("v-if", true),
                  ($options.signatureVisible)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _cache[26] || (_cache[26] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
                        _createElementVNode("footer", {
                          innerHTML: $props.post.user.sig
                        }, null, 8 /* PROPS */, _hoisted_37)
                      ], 64 /* STABLE_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
                ], 4 /* STYLE */),
                _createVNode(_component_vue_tags, {
                  tags: $options.tags,
                  class: "tag-clouds-md mt-2 mb-2"
                }, null, 8 /* PROPS */, ["tags"]),
                ($props.post.edit_count && _ctx.is_mode_linear)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createElementVNode("strong", null, [
                        _createTextVNode(" edytowany " + _toDisplayString($props.post.edit_count) + "x, ostatnio: ", 1 /* TEXT */),
                        _createVNode(_component_vue_username, {
                          user: $props.post.editor
                        }, null, 8 /* PROPS */, ["user"])
                      ]),
                      _cache[27] || (_cache[27] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
                      _createVNode(_component_vue_timeago, {
                        datetime: $props.post.updated_at
                      }, null, 8 /* PROPS */, ["datetime"])
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.is_mode_linear)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                      ($props.post.comments_count > Object.keys($props.post.comments).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                            _createElementVNode("span", {
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadComments($props.post))),
                              class: "cursor-pointer"
                            }, [
                              _createVNode(_component_vue_icon, { name: "postFoldedCommentsUnfold" }),
                              _createTextVNode(" Zobacz " + _toDisplayString(_ctx.declination($options.totalComments, ['pozostały', 'pozostałe', 'pozostałe'])) + " " + _toDisplayString($options.totalComments) + " " + _toDisplayString(_ctx.declination($options.totalComments, ['komentarz', 'komentarze', 'komentarzy'])), 1 /* TEXT */)
                            ])
                          ]))
                        : _createCommentVNode("v-if", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.post.comments, (comment) => {
                        return (_openBlock(), _createBlock(_component_vue_comment, {
                          key: comment.id,
                          comment: comment
                        }, null, 8 /* PROPS */, ["comment"]))
                      }), 128 /* KEYED_FRAGMENT */)),
                      _withDirectives(_createVNode(_component_vue_comment_form, {
                        comment: $data.commentDefault,
                        onSave: _cache[6] || (_cache[6] = ($event: any) => ($data.isCommenting = false)),
                        onCancel: _cache[7] || (_cache[7] = ($event: any) => ($data.isCommenting = false)),
                        ref: "comment-form",
                        class: "mt-2"
                      }, null, 8 /* PROPS */, ["comment"]), [
                        [_vShow, $data.isCommenting]
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */), [
                [_vShow, !$props.post.is_editing]
              ]),
              ($props.post.is_editing)
                ? (_openBlock(), _createBlock(_component_vue_form, {
                    key: 1,
                    ref: "form",
                    class: _normalizeClass(["col-12 mt-2 mb-2", {'col-lg-10':_ctx.is_mode_linear}]),
                    post: $props.post,
                    "show-title-input": $options.isFirstPost,
                    "show-discuss-mode-select": false,
                    "show-tags-input": $options.isFirstPost,
                    "show-sticky-checkbox": $options.isFirstPost && $props.post.moderatorPermissions.sticky,
                    "upload-mimes": $props.uploadMimes,
                    "upload-max-size": $props.uploadMaxSize,
                    onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$store.commit('posts/editEnd', $props.post))),
                    onSave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$store.commit('posts/editEnd', $props.post)))
                  }, null, 8 /* PROPS */, ["class", "post", "show-title-input", "show-tags-input", "show-sticky-checkbox", "upload-mimes", "upload-max-size"]))
                : _createCommentVNode("v-if", true)
            ])
          ], 2 /* CLASS */),
          (!$options.authorBlocked)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass([{'collapse': $data.isCollapsed, 'backport-post-footer':_ctx.is_mode_linear}, "px-2 py-1"])
              }, [
                _createElementVNode("div", _hoisted_41, [
                  (_ctx.is_mode_linear)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["d-none", {'d-lg-block col-lg-2':_ctx.is_mode_linear}])
                      }, null, 2 /* CLASS */))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-12 d-flex", {'col-lg-10':_ctx.is_mode_linear}])
                  }, [
                    (!$props.post.deleted_at)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                          (!$options.hidden && _ctx.is_mode_tree)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "post-action",
                                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.checkAuth(_ctx.vote, $props.post))),
                                "aria-label": $options.voters,
                                "data-balloon-pos": "up",
                                "data-balloon-break": ""
                              }, [
                                ($props.post.is_voted)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_44, [
                                      _createVNode(_component_vue_icon, { name: "postVoted" })
                                    ]))
                                  : (_openBlock(), _createBlock(_component_vue_icon, {
                                      key: 1,
                                      name: "postVote"
                                    })),
                                _createElementVNode("span", {
                                  textContent: _toDisplayString($props.post.score),
                                  class: "ms-1"
                                }, null, 8 /* PROPS */, _hoisted_45),
                                _cache[28] || (_cache[28] = _createElementVNode("span", { class: "d-none d-sm-inline ms-1" }, "Doceń", -1 /* HOISTED */))
                              ], 8 /* PROPS */, _hoisted_43))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("div", _hoisted_46, [
                            _createElementVNode("span", _hoisted_47, [
                              _createVNode(_component_vue_icon, { name: "postShare" }),
                              _cache[29] || (_cache[29] = _createElementVNode("span", { class: "d-none d-sm-inline ms-1" }, "Udostępnij", -1 /* HOISTED */))
                            ]),
                            _createElementVNode("div", _hoisted_48, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.shareDropdownItems, (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  class: "dropdown-item",
                                  onClick: item.action
                                }, [
                                  _createVNode(_component_vue_icon, {
                                    name: item.iconName
                                  }, null, 8 /* PROPS */, ["name"]),
                                  _createTextVNode(" " + _toDisplayString(item.title), 1 /* TEXT */)
                                ], 8 /* PROPS */, _hoisted_49))
                              }), 256 /* UNKEYED_FRAGMENT */))
                            ])
                          ]),
                          ($props.post.permissions.accept)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: "post-action",
                                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.accept($props.post))),
                                title: "Kliknij, aby ustawić tę odpowiedź jako zaakceptowaną"
                              }, [
                                ($props.post.is_accepted)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(_component_vue_icon, {
                                        name: "postAcceptAccepted",
                                        class: "text-primary"
                                      }),
                                      _cache[30] || (_cache[30] = _createElementVNode("span", { class: "d-none d-sm-inline ms-1" }, "Zaakceptuj", -1 /* HOISTED */))
                                    ], 64 /* STABLE_FRAGMENT */))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createVNode(_component_vue_icon, { name: "postAccept" }),
                                      _cache[31] || (_cache[31] = _createElementVNode("span", { class: "d-none d-sm-inline ms-1" }, "Zaakceptuj", -1 /* HOISTED */))
                                    ], 64 /* STABLE_FRAGMENT */))
                              ]))
                            : _createCommentVNode("v-if", true),
                          (_ctx.is_mode_linear && (!$props.post.is_locked || $props.post.permissions.write))
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 2,
                                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.checkAuth($options.comment))),
                                class: "post-action"
                              }, [
                                ($data.isCommenting)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_50, [
                                      _createVNode(_component_vue_icon, { name: "postCommentActive" })
                                    ]))
                                  : (_openBlock(), _createBlock(_component_vue_icon, {
                                      key: 1,
                                      name: "postComment"
                                    })),
                                _cache[32] || (_cache[32] = _createElementVNode("span", { class: "d-none d-sm-inline ms-1" }, "Komentuj", -1 /* HOISTED */))
                              ]))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : _createCommentVNode("v-if", true),
                    ($props.post.permissions.write)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass({'ms-auto':_ctx.is_mode_linear})
                        }, [
                          (!$props.post.deleted_at)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (_ctx.is_mode_linear)
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 0,
                                      onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => ($options.replyMentionAuthor && $options.replyMentionAuthor(...args))),
                                      class: "post-action",
                                      title: "Odpowiedz na ten post"
                                    }, [
                                      _createVNode(_component_vue_icon, { name: "postMentionAuthor" })
                                    ]))
                                  : _createCommentVNode("v-if", true),
                                (!$props.treeTopicPostFirst)
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 1,
                                      onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => ($options.replyQuoteContent && $options.replyQuoteContent(...args))),
                                      class: "post-action",
                                      title: "Dodaj cytat do pola odpowiedzi"
                                    }, [
                                      _createVNode(_component_vue_icon, { name: "postAnswerQuote" }),
                                      _cache[33] || (_cache[33] = _createElementVNode("span", { class: "d-none d-sm-inline ms-1" }, "Odpowiedz", -1 /* HOISTED */))
                                    ]))
                                  : _createCommentVNode("v-if", true)
                              ], 64 /* STABLE_FRAGMENT */))
                            : _createCommentVNode("v-if", true),
                          ($options.postDropdownVisible)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                                _createElementVNode("span", _hoisted_52, [
                                  _createVNode(_component_vue_icon, { name: "postMenuDropdown" })
                                ]),
                                _createElementVNode("div", _hoisted_53, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.postDropdownItems, (item) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                                      (item.divider)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_54))
                                        : (item.link)
                                          ? (_openBlock(), _createElementBlock("a", {
                                              key: 1,
                                              class: "dropdown-item",
                                              href: item.link
                                            }, [
                                              _createVNode(_component_vue_icon, {
                                                name: item.iconName
                                              }, null, 8 /* PROPS */, ["name"]),
                                              _createTextVNode(" " + _toDisplayString(item.title), 1 /* TEXT */)
                                            ], 8 /* PROPS */, _hoisted_55))
                                          : (_openBlock(), _createElementBlock("span", {
                                              key: 2,
                                              class: _normalizeClass(["dropdown-item", {disabled: item.disabled}]),
                                              onClick: item.action
                                            }, [
                                              _createVNode(_component_vue_icon, {
                                                name: item.iconName
                                              }, null, 8 /* PROPS */, ["name"]),
                                              _createTextVNode(" " + _toDisplayString(item.title), 1 /* TEXT */)
                                            ], 10 /* CLASS, PROPS */, _hoisted_56))
                                    ], 64 /* STABLE_FRAGMENT */))
                                  }), 256 /* UNKEYED_FRAGMENT */))
                                ])
                              ]))
                            : _createCommentVNode("v-if", true)
                        ], 2 /* CLASS */))
                      : _createCommentVNode("v-if", true)
                  ], 2 /* CLASS */)
                ])
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (_ctx.is_mode_tree && !$props.post.deleted_at && $data.treeTopicReplyVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                (_ctx.is_mode_linear)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_58))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-12", {'col-lg-10':_ctx.is_mode_linear}])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["px-2 pb-2", {'ps-lg-0 pe-lg-4 pb-lg-3':_ctx.is_mode_linear}])
                  }, [
                    _createVNode(_component_vue_form, {
                      "tree-answer-post-id": $props.post.id,
                      post: $data.postDefault,
                      onSave: $options.formSaved,
                      ref: "topicReply"
                    }, null, 8 /* PROPS */, ["tree-answer-post-id", "post", "onSave"])
                  ], 2 /* CLASS */)
                ], 2 /* CLASS */)
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_vue_modal, {
            ref: "delete-modal",
            onDelete: $options.deletePostCloseModalDelete,
            reasons: _ctx.reasons
          }, null, 8 /* PROPS */, ["onDelete", "reasons"])
        ], 10 /* CLASS, PROPS */, _hoisted_3),
        _createVNode(_component_vue_gallery, {
          images: $data.galleryImages,
          index: $data.galleryImageIndex,
          onClose: $options.closeGallery
        }, null, 8 /* PROPS */, ["images", "index", "onClose"])
      ])
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_59, [
      ($options.hasDeeperChildren || $options.childrenFolded)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tree-post position-relative", $options.postIndentCssClasses])
          }, [
            ($options.guiderailVisible)
              ? (_openBlock(), _createBlock(_component_vue_post_guiderail, {
                  key: 0,
                  "link-to-child": "none",
                  "links-to-parent": false,
                  "parent-levels": $options.parentLevels,
                  expanded: $options.childrenFolded,
                  onToggle: $options.guiderailToggle
                }, null, 8 /* PROPS */, ["parent-levels", "expanded", "onToggle"]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_60, [
              ($options.hasDeeperChildren)
                ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                    _createElementVNode("a", {
                      class: "me-2",
                      href: $options.postSubTreeUrl
                    }, [
                      _createVNode(_component_vue_icon, { name: "postGuiderailExpanded" }),
                      _createTextVNode(" " + _toDisplayString($options.postAnswersAuthorsSeeMore), 1 /* TEXT */)
                    ], 8 /* PROPS */, _hoisted_62)
                  ]))
                : _createCommentVNode("v-if", true),
              ($options.childrenFolded && $options.hasChildren)
                ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                    _createElementVNode("a", {
                      class: "me-2",
                      onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => ($options.unfoldChildren && $options.unfoldChildren(...args))),
                      href: "javascript:"
                    }, [
                      _createVNode(_component_vue_icon, { name: "postGuiderailExpanded" }),
                      _createTextVNode(" " + _toDisplayString($options.postAnswersAuthorsSeeMore), 1 /* TEXT */)
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.postAnswersAuthorsDistinct, (author) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_64, [
                  _createVNode(_component_vue_avatar, {
                    photo: author.photo,
                    name: author.name,
                    initials: author.initials,
                    class: "img-thumbnail me-1"
                  }, null, 8 /* PROPS */, ["photo", "name", "initials"])
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}